import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/vercel/path0/frontend/src/components/navigation/breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicZone"] */ "/vercel/path0/frontend/src/components/ui/dynamic-zone.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Media"] */ "/vercel/path0/frontend/src/components/ui/media.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NoticeWithMessage"] */ "/vercel/path0/frontend/src/components/ui/notice-with-message.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/ui/rich-text-json-renderer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
